import { render, staticRenderFns } from "./jak-kupic-akcje-bmw.md?vue&type=template&id=32fee2c6&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports